.home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;

}

.home-content {
  position: relative;
  padding-left: 2rem;
  border-left: 0.3rem solid #fff;
  /* Outer border */
  border-left: 0.3rem double #ddd;
  /* Inner double border */
}


.home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 2rem;
}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.5;
}

.home-content p {
  font-size: 1.6rem;
  line-height: 2rem;

}

.social-media a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 4rem;
  width: 4rem;
  color: var(--main-color);
  font-size: 2rem;
  border: 0.2rem solid var(--main-color);
  border-radius: 50%;
  margin: 3rem 1.5rem 3rem 0;
  transition: 0.5s ease;
}

.social-media a:hover {
  background: var(--main-color);
  color: var(--second-bg-color);
  box-shadow: 0 0 1rem var(--main-color);
}

.portfolio-image img {
  width: 25vw;
  border-radius: 50%;
  box-shadow: 0 1rem 1.5rem var(--main-color);
  animation: floatImage 4s ease-in-out infinite;
}

@keyframes floatImage {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2.4rem);
  }

  100% {
    transform: translateY(0);
  }
}

.btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  font-size: 1.6rem;
  color: var(--second-bg-color);
  background-color: var(--main-color);
  border: 2rem;
  border-radius: 4rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  box-shadow: 0 0 1rem var(--main-color);
  transition: 0.5s ease;
}

.btn:hover {
  box-shadow: none;
}

@media(max-width:768px) {
  .home-section {
    flex-direction: column;
  }

  .home-content {
    border: none;
  }

  .portfolio-image img {
    width: 70vw;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .home-content h3 {
    font-size: 2.6rem;
  }

  .home-content h1 {
    font-size: 5rem;
  }
}