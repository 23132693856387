.contact-section {
  background-color: var(--third-bg-color);
}

.contact-section h2 {
  padding-bottom: 2rem;
  font-size: 4.5rem;
  text-align: center;
}

.contact-section form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-group input,
.contact-section form textarea {
  width: 49%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background: var(--second-bg-color);
  border-radius: 0.8rem;
  margin: 0.7rem 0;
}

.contact-section form textarea {
  width: 100%;
  resize: none;
}


.contact-section .btn {
  margin-top: 2rem;
  cursor: pointer;
}


@media (max-width: 600px) {

  .contact-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .input-group input {
    width: 100%;
  }
}