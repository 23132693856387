* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --bg-color: #1f242d;
  --second-bg-color: #323946;
  --third-bg-color: #161e2a;
  --text-color: #fff;
  --main-color: #0ef;
  --second-main-color: #067a9b;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;

}

span {
  color: var(--main-color);
}

/*Breakpoints */

@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }

  section {
    padding: 10rem 3% 2rem
  }

  .services-section {
    padding-bottom: 7rem;
  }

  .portfolio-section {
    padding-bottom: 7rem;
  }

  .footer {
    padding: 2rem 3%;
  }
}


@media(max-width:450px) {
  html {
    font-size: 50%;
  }
}

@media(max-width:365px) {
  .portfolio-image img {
    max-width: 90vw;
  }

  .about-image img {
    max-width: 90vw;
  }
}