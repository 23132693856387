.skills-section h2 {
  padding-bottom: 5rem;
  font-size: 4.5rem;
  text-align: center;
}

.skills-container {
  display: flex;
  align-items: flex-start;
  gap: 8rem;
}

.skill-type-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  align-items: center;
  justify-content: center;
}

.skill-type-box {
  display: flex;
  width: 25rem;
  height: 8rem;
  font-size: 2rem;
  color: var(--text-color);
  background-color: var(--second-bg-color);
  border: 0.2rem solid var(--third-bg-color);
  padding: 2.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 2rem var(--third-bg-color);
  transition: 0.5s ease-in-out;
  position: relative;

}

.skill-type-box:hover,
.skill-type-box.active {
  transform: scale(1.1);
  border: 0.1rem solid var(--main-color);
  box-shadow: 0 0 0.5rem var(--main-color);
}

.skill-type-box:hover i,
.skill-type-box.active i {
  border: 0.1rem solid var(--main-color);
  box-shadow: 0 0 0.5rem var(--main-color);

}

.skill-type-box i {
  position: absolute;
  top: -1rem;
  left: -1rem;
  font-size: 4rem;
  border: 0.1rem solid var(--third-bg-color);
  background-color: var(--second-bg-color);
  padding: 0.5rem;
  border-radius: 1rem;
  transition: 0.5s ease-in-out;
}


.skills-details-container {
  display: flex;
  flex-direction: column;
  background-color: var(--second-bg-color);
  border: 0.1rem solid var(--third-bg-color);
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem var(--main-color);
  padding: 0 2rem 1rem 2rem;
  width: 100%;
  transition: 0.5s ease-in;
}

.skills-details-container:hover {
  transform: scale(1.05);
}

.skill-details-box h1 {
  line-height: 5rem;
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 0.1rem solid var(--main-color);
  /* Underline effect */
  border-shadow: var(--main-color);
  margin-bottom: 2rem;
}

.skill-box {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}


.progress-bar {
  width: 100%;
  /* Full width */
  height: 0.8rem;
  /* Height of the bar */
  background-color: var(--third-bg-color);
  /* Background color for the bar */
  border-radius: 0.5rem;
  /* Rounded corners */
  margin-top: 0.5rem;
  /* Space between skill and bar */
  margin-bottom: 2.5rem;
}

.progress {
  height: 100%;
  /* Full height of the parent */
  background: linear-gradient(90deg, transparent -200%, var(--main-color) 100%);
  /* Gradient from transparent to main color */
  border-radius: 0.5rem;
  /* Rounded corners */
  transition: all 0.5s ease-in-out;
}




@media (max-width: 991px) {
  .skill-type-box {
    width: 20rem;
    height: 6rem;
    font-size: 1.6rem;
  }
}

@media (max-width:768px) {
  .skills-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .skill-type-box i {
    font-size: 3rem;
  }

  .skills-details-container {
    width: 60%;
  }

}

@media (max-width:618px) {
  .skill-type-box {
    width: 18rem;
    height: 6rem;
    font-size: 1.6rem;
  }

  .skill-type-box i {
    font-size: 2.8rem;
  }

  .skill-details-box {
    width: 100%
  }

  .skills-details-container {
    width: 90%;
  }

}