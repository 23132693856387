.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 2.5rem;
  color: var(--text-color);
  font-weight: 600;
  cursor: default;
}

.navbar a {
  font-size: 1.7rem;
  margin-left: 4rem;
  color: var(--text-color);
}

.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
  transition: .5s;
}

#menu-logo {
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
}


@media(max-width:768px) {
  #menu-logo {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background-color: var(--bg-color);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }
}