.section-projects h2 {
  font-size: 4.5rem;
  text-align: center;
  padding-bottom: 5rem;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2.5rem;
}

.project-box {
  position: relative;
  border-radius: 2rem;
  box-shadow: 0 0 2rem var(--second-bg-color);
  overflow: hidden;
  height: 25rem;
  display: flex;
}

.project-box img {
  width: 100%;
  transition: 0.5s ease;
}

.project-box:hover img {
  transform: scale(1.1);
}

.box-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), var(--second-main-color));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  transform: translateY(100%);
  transition: 0.5s ease;
}

.project-box:hover .box-layer {
  transform: translateY(0%);
}

.box-layer h4 {
  font-size: 2rem;
}

.box-layer p {
  font-size: 1.4rem;
  margin: .3rem 0 1rem;
}

.box-layer a {
  margin-top: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--text-color);
  border-radius: 50%;
}

.box-layer a i {
  font-size: 1.5rem;
  color: var(--second-bg-color);
}


@media(max-width:768px) {
  .section-projects h2 {
    margin-bottom: 3rem;
  }

  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width:617px) {

  .section-projects {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .projects-container {
    grid-template-columns: 1fr;
  }
}